import React, { useContext, useEffect, useState } from 'react';
import Loader from './Loader';
import axios from 'axios';
import Navigation from './Navigation';
import { motion } from 'framer-motion';
import { Context } from '../components/LanguageSwitcher';
import ParserLink from './ParserLink';

const Product = (props) => {
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [titleLang, setTitleLang] = useState(props.data.title);
    const context = useContext(Context);

    useEffect(() => {
        setLoading(true);
        let idLang = props.data.id;
        setTitleLang(props.data.title);


        if (context.locale === 'en-US') {
            idLang = props.data.id_en;
        }

        axios.get(process.env.REACT_APP_WORDPRESS_URL_POST + idLang).then((res) => {
            setProductData(res.data);
            setLoading(false);
        });

        window.history.pushState(null, null, document.URL);
        window.addEventListener("popstate", (event) => {
            window.location.replace('/#products');
        });

    }, [context.locale, props.data.id, props.data.id_en, props.data.title]);

    return (
        <div>
            {loading
                ?
                <Loader />
                :
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}>
                    <Navigation />
                    <div className="sections-container af">
                        <div className={'wrapper-content-header ' + props.data.class_img}>
                            <h1>{titleLang}</h1>
                        </div>
                        <div className="wrapper-content-body">
                            {productData.content ? <ParserLink rendered={productData.content.rendered} /> : ''}
                        </div>
                        <div className="wrapper-content-footer"></div>
                    </div>
                </motion.div>
            }
        </div>
    );
};

export default Product;