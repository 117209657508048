import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Context } from 'react-intl/src/components/injectIntl';
import Navigation from '../components/Navigation';
import Loader from '../components/Loader';
import ParserLink from '../components/ParserLink';

const Values = () => {

    const textTranslator = useIntl();

    const data = {
        id: 401,
        id_en: 403,
        title: textTranslator.formatMessage({ id: 'values.title' })
    }

    const [valuesData, setValuesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [titleLang, setTitleLang] = useState(data.title);

    const context = useContext(Context);

    useEffect(() => {
        setLoading(true);
        let idLang = data.id;
        setTitleLang(data.title);


        if (context.locale === 'en-US') {
            idLang = data.id_en;
        }

        axios.get(process.env.REACT_APP_WORDPRESS_URL_POST + idLang).then((res) => {
            setValuesData(res.data);
            setLoading(false);
        });
    }, [context.locale, data.id, data.id_en, data.title]);

    return (
        <div>
            {loading
                ?
                <Loader />
                :
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}>
                    <Navigation />
                    <div className="sections-container values">
                        <div className="wrapper-values">
                            <div className="values-body">
                                <div className='header'>
                                    <h1>{titleLang}</h1>
                                </div>
                                <div className='body'>
                                    {valuesData.content ? <ParserLink rendered={valuesData.content.rendered} /> : ''}
                                </div>

                            </div>
                        </div>
                    </div>

                </motion.div>
            }
        </div>
    );
};

export default Values;