import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Context } from '../components/LanguageSwitcher';

const Metas = () => {

    const context = useContext(Context);
    const textTranslator = useIntl();

    let lang = 'fr';
    if (context.locale !== 'fr-FR') {
        lang = 'en';
    }

    return (
        <Helmet>
            <meta name="description" content={textTranslator.formatMessage({ id: 'metas.description' })} />
            <title>{textTranslator.formatMessage({ id: 'metas.title' })}</title>
            <script defer event-language={lang} data-domain="bordasmedia.com" src="https://pls.bordasmedia.com/js/script.pageview-props.js"></script>
        </Helmet>
    );
};

export default Metas;