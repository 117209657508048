import React, { useContext, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FormattedMessage, useIntl } from 'react-intl';
import { Context } from '../components/LanguageSwitcher';

const Navigation = (props) => {

    const context = useContext(Context);
    const textTranslator = useIntl();

    let lang = 'fr-FR';
    if (context.locale !== 'fr-FR') {
        lang = 'en-US';
    }

    useEffect(() => {
        const sectionsContainer = document.querySelector('.sections-container');
        sectionsContainer.addEventListener('scroll', (e) => {
            let sticky = document.querySelector('.navbar');
            let scroll = sectionsContainer.scrollTop;
            if (scroll >= 56) {
                sticky.classList.add('fixed-top');
            } else {
                sticky.classList.remove('fixed-top');
            }
        });

        const navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.addEventListener('click', (e) => {
            document.querySelector('.navbar-expand-md .navbar-collapse').classList.toggle('show');
        });

        const navItem = document.querySelector('.nav-item');
        navItem.addEventListener('click', (e) => {
            document.querySelector('.navbar-expand-md .navbar-collapse').classList.toggle('show');
        });
    }, []);


    return (
        <header>
            <nav className="navbar navbar-expand-md scrolling-navbar">
                <div className="container">
                    <Link to="/#home" className="navbar-logo" title="BordasMedia - Accueil">
                        <img src="/assets/img/logo.png" srcSet="/assets/img/logo.png 2x" width="200" height="24" alt="logo bordas" fetchpriority="high" />
                    </Link>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="hambgr">
                            <hr />
                            <hr />
                            <hr />
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto w-100 justify-content-end">
                            <li className="nav-item">
                                <Link to="/#home" className="nav-link page-scroll" title="Home">
                                    <FormattedMessage id="nav.home" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#presentation" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.aboutus' })}>
                                    <FormattedMessage id="nav.aboutus" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#sites" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.sites' })}>
                                    <FormattedMessage id="nav.sites" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#products" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.products' })}>
                                    <FormattedMessage id="nav.products" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#team" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.team' })}>
                                    <FormattedMessage id="nav.team" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#references" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.references' })}>
                                    <FormattedMessage id="nav.references" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#contact" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.contact' })}>
                                    <FormattedMessage id="nav.contact" />
                                </Link>
                            </li>
                        </ul>
                        <div className='custom-language-switcher'>
                            <select className='language-switcher' value={lang} onChange={context.handleSelectLanguage}>
                                <option value="fr-FR">Français</option>
                                <option value="en-US">English</option>
                            </select>
                        </div>
                    </div>
                </div>
            </nav>
        </header >
    );
};

export default Navigation;