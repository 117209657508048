import React from 'react';
import Site from '../../components/Site';

const Audiofanzineen = () => {
    const data = {
        id: 11,
        id_en: 358,
        context: 'afen',
        title: 'Audiofanzine EN',
        title_en: 'Audiofanzine EN',
        div_class: 'logo-af',
        img: '/assets/img/logo-af.png',
        img_w: 441,
        img_h: 80,
        url: 'https://en.audiofanzine.com/',
        flag_fr: false,
        flag_en: true,
        flag_us: false,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};

export default Audiofanzineen;