import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const ParserLink = ({ rendered }) => {

    const parse = require('html-react-parser');

    return (
        parse(rendered, {
            replace: function (domNode) {
                if (domNode.type === 'tag' && domNode.name === 'a') {
                    if (domNode.attribs.target === '_blank' && domNode.attribs.rel === 'noreferrer noopener') {
                        return rendered;
                    } else {
                        return <Link to={domNode.attribs.href}>{domNode.children[0].data}</Link>;
                    }
                }
            }
        })
    )
};

export default ParserLink;