import React from 'react';
import Site from '../../components/Site';

const Futureproducers = () => {
    const data = {
        id: 15,
        id_en: 362,
        context: 'futurep',
        title: 'Future Producers',
        title_en: 'Future Producers',
        div_class: 'logo-futureproducers',
        img: '/assets/img/websites/futureproducers-dark.png',
        img_w: 193,
        img_h: 48,
        url: 'https://www.futureproducers.com/forums/',
        flag_fr: false,
        flag_en: false,
        flag_us: true,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};

export default Futureproducers;