import React from 'react';
import ProductAdvertising from '../../../components/ProductAdvertising';
import { useIntl } from 'react-intl';

const Sponsoring = () => {

    const textTranslator = useIntl();

    const data = {
        id: 36,
        id_en: 321,
        context: 'sponsoring',
        title: textTranslator.formatMessage({ id: 'products.blocs.sponsoring' })
    }
    return (
        <div>
            <ProductAdvertising data={data} />
        </div>
    );
};

export default Sponsoring;