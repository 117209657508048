import React from 'react';
import Home from './pages/Home';
import Error from './pages/Error';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Audiofanzinefr from './pages/sites/Audiofanzinefr';
import Audiofanzineen from './pages/sites/Audiofanzineen';
import Homerecording from './pages/sites/Homerecording';
import Musicgearreview from './pages/sites/Musicgearreview';
import Rigtalk from './pages/sites/Rigtalk';
import Futureproducers from './pages/sites/Futureproducers';
import Advertising from './pages/products/Advertising';
import Services from './pages/products/Services';
import Selling from './pages/products/Selling';
import Banners from './pages/products/advertising/Banners';
import Billboards from './pages/products/advertising/Billboards';
import Slidein from './pages/products/advertising/Slidein';
import Newsletters from './pages/products/advertising/Newsletters';
import Homepage from './pages/products/advertising/Homepage';
import Dynamicbanners from './pages/products/advertising/Dynamicbanners';
import Contests from './pages/products/advertising/Contests';
import Socials from './pages/products/advertising/Socials';
import Sponsoring from './pages/products/advertising/Sponsoring';
import Values from './pages/Values';
import Ressources from './pages/Ressources';
import News from './pages/products/advertising/News';
import ScrollToAnchor from './components/ScrollToAnchor';
import Metas from './components/Metas';


const App = () => {

  return (
    <BrowserRouter>
      <Metas />
      <ScrollToAnchor />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/sites/audiofanzine.html" element={<Audiofanzinefr />}></Route>
        <Route path="/sites/audiofanzine-en.html" element={<Audiofanzineen />}></Route>
        <Route path="/sites/futureproducers.html" element={<Futureproducers />}></Route>
        <Route path="/sites/homerecording.html" element={<Homerecording />}></Route>
        <Route path="/sites/musicgearreview.html" element={<Musicgearreview />}></Route>
        <Route path="/sites/rigtalk.html" element={<Rigtalk />}></Route>
        <Route path="/products/advertising.html" element={<Advertising />}></Route>
        <Route path="/products/services.html" element={<Services />}></Route>
        <Route path="/products/selling.html" element={<Selling />}></Route>
        <Route path="/products/advertising/banners.html" element={<Banners />}></Route>
        <Route path="/products/advertising/billboards.html" element={<Billboards />}></Route>
        <Route path="/products/advertising/slidein.html" element={<Slidein />}></Route>
        <Route path="/products/advertising/newsletters.html" element={<Newsletters />}></Route>
        <Route path="/products/advertising/homepage.html" element={<Homepage />}></Route>
        <Route path="/products/advertising/dynamic-banners.html" element={<Dynamicbanners />}></Route>
        <Route path="/products/advertising/contests.html" element={<Contests />}></Route>
        <Route path="/products/advertising/socials.html" element={<Socials />}></Route>
        <Route path="/products/advertising/sponsoring.html" element={<Sponsoring />}></Route>
        <Route path="/products/advertising/newshighlight.html" element={<News />}></Route>
        <Route path="/values.html" element={<Values />}></Route>
        <Route path="/ressources.html" element={<Ressources />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;