import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Navigation from '../components/Navigation';

const Error = () => {

    return (
        <div>
            <Navigation />
            <div className="sections-container error">
                <div className="wrapper-error">
                    <div className="error-body">
                        <h1><FormattedMessage id="error.h1" /> </h1>
                        <span><FormattedMessage id="error.span" /></span>
                        <div>
                            <NavLink to="/" title="Revenir à l’accueil">
                                <FormattedMessage id="error.link.title" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error;