import React from 'react';
import Site from '../../components/Site';

const Audiofanzinefr = () => {
    const data = {
        id: 1,
        id_en: 349,
        context: 'affr',
        title: 'Audiofanzine France',
        title_en: 'Audiofanzine France',
        div_class: 'logo-af',
        img: '/assets/img/logo-af.png',
        img_w: 441,
        img_h: 80,
        url: 'https://fr.audiofanzine.com/',
        flag_fr: true,
        flag_en: false,
        flag_us: false,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};

export default Audiofanzinefr;