import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../translations/en.json';
import French from '../translations/fr.json';

export const Context = React.createContext();
const local = navigator.language;

let lang = English;
if (local === 'fr-FR') {
    lang = French;
}

const LanguageSwitcher = (props) => {

    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    const handleSelectLanguage = (e) => {
        const choosenLang = e.target.value;
        setLocale(choosenLang);
        if (choosenLang === 'fr-FR') {
            setMessages(French);
        } else {
            setMessages(English);
        }
    }

    return (
        <Context.Provider value={{ locale, handleSelectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
};


export default LanguageSwitcher;