import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

const CustomTextarea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const textTranslator = useIntl();
    return (
        <div className="form-item">
            <label>{label}</label>
            <textarea
                {...field}
                {...props}
                className={meta.touched && meta.error ? "has-error" : ""}>
            </textarea>
            {meta.touched && meta.error ? <p className="error">{textTranslator.formatMessage({ id: meta.error })}</p> : ""}
        </div>
    );
};

export default CustomTextarea;