import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation';
import Loader from './Loader';
import { motion } from 'framer-motion';
import ParserLink from './ParserLink';
import { Context } from 'react-intl/src/components/injectIntl';
import { FormattedMessage, useIntl } from 'react-intl';

const ProductAdvertising = (props) => {
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [titleLang, setTitleLang] = useState(props.data.title);

    const context = useContext(Context);
    const textTranslator = useIntl();

    useEffect(() => {

        setLoading(true);
        let idLang = props.data.id;
        setTitleLang(props.data.title);

        if (context.locale === 'en-US') {
            idLang = props.data.id_en;
        }

        axios.get(process.env.REACT_APP_WORDPRESS_URL_POST + idLang).then((res) => {
            setProductData(res.data);
            setLoading(false);
        });
    }, [context.locale, props.data.id, props.data.id_en, props.data.title]);

    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", (event) => {
        window.location.replace(' /products/advertising.html');
    });

    return (
        <div>
            {loading
                ?
                <Loader />
                :
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}>
                    <Navigation />
                    <div className="sections-container af products">
                        <div className="wrapper-content-body">
                            <h1>{titleLang}</h1>
                            {productData.content ? <ParserLink rendered={productData.content.rendered} /> : ''}
                        </div>
                        <div className="wrapper-content-footer">
                            <div className="title"><FormattedMessage id="products.blocs.title" /></div>
                            <ul>
                                {
                                    props.data.context !== 'banners' ?
                                        <li>
                                            <NavLink to="/products/advertising/banners.html" title={textTranslator.formatMessage({ id: 'products.blocs.banners.alt' })}>
                                                <FormattedMessage id="products.blocs.banners" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'bannersd' ?
                                        <li>
                                            <NavLink to="/products/advertising/dynamic-banners.html" title={textTranslator.formatMessage({ id: 'products.blocs.dynamicbanners.alt' })}>
                                                <FormattedMessage id="products.blocs.dynamicbanners" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'billboards' ?
                                        <li>
                                            <NavLink to="/products/advertising/billboards.html" title={textTranslator.formatMessage({ id: 'products.blocs.takeover.alt' })}>
                                                <FormattedMessage id="products.blocs.takeover" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'slidein' ?
                                        <li>
                                            <NavLink to="/products/advertising/slidein.html" title={textTranslator.formatMessage({ id: 'products.blocs.slidein.alt' })}>
                                                <FormattedMessage id="products.blocs.slidein" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'newsletter' ?
                                        <li>
                                            <NavLink to="/products/advertising/newsletters.html" title={textTranslator.formatMessage({ id: 'products.blocs.newsletter.alt' })}>
                                                <FormattedMessage id="products.blocs.newsletter" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'homepage' ?
                                        <li>
                                            <NavLink to="/products/advertising/homepage.html" title={textTranslator.formatMessage({ id: 'products.blocs.homepage.alt' })}>
                                                <FormattedMessage id="products.blocs.homepage" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    /* props.data.context !== 'contests' ?
                                         <li>
                                             <NavLink to="/products/advertising/contests.html" title={textTranslator.formatMessage({ id: 'products.blocs.contests.alt' })}>
                                                 <FormattedMessage id="products.blocs.contests" />
                                             </NavLink>
                                         </li>
                                         : ''*/
                                }
                                {
                                    props.data.context !== 'sponsoring' ?
                                        <li>
                                            <NavLink to="/products/advertising/sponsoring.html" title={textTranslator.formatMessage({ id: 'products.blocs.sponsoring.alt' })}>
                                                <FormattedMessage id="products.blocs.sponsoring" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'socials' ?
                                        <li>
                                            <NavLink to="/products/advertising/socials.html" title={textTranslator.formatMessage({ id: 'products.blocs.socials.alt' })}>
                                                <FormattedMessage id="products.blocs.socials" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                                {
                                    props.data.context !== 'news' ?
                                        <li>
                                            <NavLink to="/products/advertising/newshighlight.html" title={textTranslator.formatMessage({ id: 'products.blocs.news.alt' })}>
                                                <FormattedMessage id="products.blocs.news" />
                                            </NavLink>
                                        </li>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                </motion.div>
            }
        </div>
    );
};

export default ProductAdvertising;