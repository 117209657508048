import React from 'react';
import Product from '../../components/Product';
import { useIntl } from 'react-intl';

const Selling = () => {

    const textTranslator = useIntl();

    const data = {
        id: 42,
        id_en: 315,
        title: textTranslator.formatMessage({ id: 'home.products.bloc.selling.title' }),
        class_img: 'selling'
    }
    return (
        <div>
            <Product data={data} />
        </div>
    );
};

export default Selling;