import React from 'react';
import ProductAdvertising from '../../../components/ProductAdvertising';
import { useIntl } from 'react-intl';

const News = () => {

    const textTranslator = useIntl();

    const data = {
        id: 1620, // dev 450
        id_en: 1662, // dev 458
        context: 'news',
        title: textTranslator.formatMessage({ id: 'products.blocs.news' })
    }
    return (
        <div>
            <ProductAdvertising data={data} />
        </div>
    );
};

export default News;