import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Navigation from './Navigation';
import { NavLink } from 'react-router-dom';
import Loader from './Loader';
import { motion } from 'framer-motion';
import ParserLink from './ParserLink';
import { Context } from 'react-intl/src/components/injectIntl';
import { FormattedMessage, useIntl } from 'react-intl';

const Site = (props) => {

    const [siteData, setSiteData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [titleLang, setTitleLang] = useState(props.data.title);

    const context = useContext(Context);
    const textTranslator = useIntl();

    useEffect(() => {
        setLoading(true);
        let idLang = props.data.id;
        setTitleLang(props.data.title);

        if (context.locale === 'en-US') {
            idLang = props.data.id_en;
            setTitleLang(props.data.title_en);
        }

        axios.get(process.env.REACT_APP_WORDPRESS_URL_POST + idLang).then((res) => {
            setSiteData(res.data);
            setLoading(false);
        });

        window.history.pushState(null, null, document.URL);
        window.addEventListener("popstate", (event) => {
            window.location.replace('/#sites');
        });

    }, [context.locale, props.data.id, props.data.id_en, props.data.title_en, props.data.title]);

    return (
        <div>
            {loading
                ?
                <Loader />
                :
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}>
                    <Navigation />
                    <div className="sections-container af">
                        <div className={props.data.div_class}>
                            <img src={props.data.img} alt={'logo ' + titleLang} width={props.data.img_w} height={props.data.img_h} srcSet={props.data.img + ' 2x'} />
                        </div>
                        <div className="wrapper-content-body">
                            <h1 className="main">{titleLang}
                                <span className="flag">
                                    {props.data.flag_fr &&
                                        <img src="/assets/img/flags/flag-fr.png" srcSet="/assets/img/flags/flag-fr.png 2x" width="30" height="30" alt="Drapeau FR" />
                                    }
                                    {
                                        props.data.flag_en &&
                                        <img src="/assets/img/flags/flag-en.png" srcSet="/assets/img/flags/flag-en.png 2x" width="30" height="30" alt="Drapeau EN" />
                                    }
                                    {
                                        props.data.flag_us &&
                                        <img src="/assets/img/flags/flag-us.png" srcSet="/assets/img/flags/flag-us.png 2x" width="30" height="30" alt="Drapeau US" />
                                    }
                                </span>
                            </h1>
                            <a className="main" href={props.data.url} title={'Visiter le site ' + titleLang}>{props.data.url}</a>
                            {siteData.content ? <ParserLink rendered={siteData.content.rendered} /> : ''}
                        </div>

                        <div className="wrapper-content-footer-two">
                            <div className="">
                                <div className="title"><FormattedMessage id="sites.blocs.title" /></div>
                                <ul className="list-othersites">
                                    {
                                        props.data.context !== 'affr' ?
                                            <li>
                                                <NavLink to="/sites/audiofanzine.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Audiofanzine France"}>
                                                    <img src="/assets/img/websites/af-fr-color.png" width="225" height="25" srcSet="/assets/img/websites/af-fr-color.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Audiofanzine France"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                    {
                                        props.data.context !== 'afen' ?
                                            <li>
                                                <NavLink to="/sites/audiofanzine-en.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Audiofanzine En"}>
                                                    <img src="/assets/img/websites/af-en-color.png" width="225" height="25" srcSet="/assets/img/websites/af-en-color.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Audiofanzine EN"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                    {
                                        props.data.context !== 'homerec' ?
                                            <li>
                                                <NavLink to="/sites/homerecording.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Homerecording"}>
                                                    <img src="/assets/img/websites/homerecording-new.png" width="225" height="25" srcSet="/assets/img/websites/homerecording-new.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Homerecording"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                    {
                                        props.data.context !== 'futurep' ?
                                            <li>
                                                <NavLink to="/sites/futureproducers.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Future Producers"}>
                                                    <img src="/assets/img/websites/futureproducers.png" width="225" height="54" srcSet="/assets/img/websites/futureproducers.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Future Producers"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                    {
                                        props.data.context !== 'rigtalk' ?
                                            <li>
                                                <NavLink to="/sites/rigtalk.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Rigtalk"}>
                                                    <img src="/assets/img/websites/rigtalk-logo@2x.png" width="225" height="130" srcSet="/assets/img/websites/rigtalk-logo@2x.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " Rigtalk"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                    {
                                        props.data.context !== 'musicgr' ?
                                            <li>
                                                <NavLink to="/sites/musicgearreview.html" title={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " MusicGear Review"}>
                                                    <img src="/assets/img/websites/musicgearreview-logo.png" width="225" height="123" srcSet="/assets/img/websites/musicgearreview-logo.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'sites.blocs.meta.title' }) + " MusicGear Review"} />
                                                </NavLink>
                                            </li>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </div>
    );
};

export default Site;