import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

const CustomInput = ({ label, ...props }) => {
    const textTranslator = useIntl();
    const [field, meta] = useField(props);
    return (
        <div className="form-item">
            <label>{label}</label>
            <input
                {...field}
                {...props}
                className={meta.touched && meta.error ? "has-error" : ""} />
            {meta.touched && meta.error ? <p className="error">{textTranslator.formatMessage({ id: meta.error })}</p> : ""}
        </div>
    );
};

export default CustomInput;