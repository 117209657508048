import React from 'react';
import ProductAdvertising from '../../../components/ProductAdvertising';
import { useIntl } from 'react-intl';

const Dynamicbanners = () => {

    const textTranslator = useIntl();

    const data = {
        id: 22,
        id_en: 347,
        context: 'bannersd',
        title: textTranslator.formatMessage({ id: 'products.blocs.dynamicbanners' })
    }

    return (
        <div>
            <ProductAdvertising data={data} />
        </div>
    );
};

export default Dynamicbanners;