import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Formik, Form } from "formik";
import contactSchema from "./form/schemas/contact";
import CustomInput from './form/CustomInput';
import CustomTextarea from './form/CustomTextarea';
import axios from "axios";
import { useIntl } from 'react-intl';

const ContactForm = () => {

    const textTranslator = useIntl();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCaptchaError, setCaptchaError] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GRECAPTCHA_SITE_KEY}`);
    }, []);

    const sendEmail = async (values, actions) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsSubmitting(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_GRECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
                axios.post(`${process.env.REACT_APP_SERVER_URL}/verify-token`, {
                    "token": token
                }).then((res) => {
                    if (res.data.success) {
                        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, values, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
                            .then((result) => {
                                actions.resetForm();
                                setIsSubmitted(true);
                                setIsSubmitting(false);
                            });
                    } else {
                        setCaptchaError(true);
                    }
                })
            });
        });
    };

    return (
        <Formik
            initialValues={{
                namesender: "",
                email: "",
                message: "",
            }}
            validationSchema={contactSchema}
            onSubmit={sendEmail}>
            {
                (props) => (

                    <Form>
                        <CustomInput
                            label={textTranslator.formatMessage({ id: 'formcontact.name.label' })}
                            name="namesender"
                            type="text"
                            autoComplete="off"
                            data-1p-ignore
                            placeholder={textTranslator.formatMessage({ id: 'formcontact.name.placeholder' })} />
                        <CustomInput
                            label={textTranslator.formatMessage({ id: 'formcontact.mail.label' })}
                            name="email"
                            type="email"
                            data-1p-ignore
                            autoComplete="off"
                            placeholder={textTranslator.formatMessage({ id: 'formcontact.mail.placeholder' })} />
                        <CustomTextarea
                            label={textTranslator.formatMessage({ id: 'formcontact.message.label' })}
                            name="message"
                            placeholder={textTranslator.formatMessage({ id: 'formcontact.message.placeholder' })} />

                        {isSubmitted && <div id="resultSender" className="success">{textTranslator.formatMessage({ id: 'formcontact.success' })}</div>}
                        {isCaptchaError && <div id="resultSender" className="error">{textTranslator.formatMessage({ id: 'formcontact.error.captcha' })}</div>}

                        <button type="submit" disabled={isSubmitting}>{textTranslator.formatMessage({ id: 'formcontact.button' })}</button>
                    </Form>
                )
            }

        </Formik >
    );
};

export default ContactForm;