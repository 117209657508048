import React from 'react';
import ProductAdvertising from '../../../components/ProductAdvertising';
import { useIntl } from 'react-intl';

const Contests = () => {

    const textTranslator = useIntl();

    const data = {
        id: 26,
        id_en: 343,
        context: 'contests',
        title: textTranslator.formatMessage({ id: 'products.blocs.contests' })
    }
    return (
        <div>
            <ProductAdvertising data={data} />
        </div>
    );
};

export default Contests;