import React from 'react';
import ProductAdvertising from '../../../components/ProductAdvertising';
import { useIntl } from 'react-intl';

const Banners = () => {

    const textTranslator = useIntl();

    const data = {
        id: 195,
        id_en: 305,
        context: 'banners',
        title: textTranslator.formatMessage({ id: 'products.blocs.banners' })

    }
    return (
        <div>
            <ProductAdvertising data={data} />
        </div>
    );
};

export default Banners;