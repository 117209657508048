import React from 'react';
import Site from '../../components/Site';

const Musicgearreview = () => {
    const data = {
        id: 19,
        id_en: 366,
        context: 'musicgr',
        title: 'Music Gear Review',
        title_en: 'Music Gear Review',
        div_class: 'logo-musicgearreview',
        img: '/assets/img/websites/musicgearreview-logo.png',
        img_w: 192,
        img_h: 100,
        url: 'https://www.musicgearreview.com',
        flag_fr: false,
        flag_en: false,
        flag_us: true,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};

export default Musicgearreview;