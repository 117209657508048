import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Navigation from '../components/Navigation';
import Loader from '../components/Loader';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const Ressources = () => {


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //api call
        setLoading(false);
    }, []);

    return (
        <div>
            {loading
                ?
                <Loader />
                :
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}>
                    <Navigation />
                    <div className="sections-container ressources">
                        <div className="wrapper-ressources">
                            <div className="ressources-body">
                                <div className='header'>
                                    <h1>
                                        <FormattedMessage id="ressources.title" />
                                    </h1>
                                </div>
                                <div className='body'>
                                    <div className='wrap-lists'>
                                        <div>
                                            <h2>Media Kits</h2>
                                            <ul>
                                                <li>
                                                    Network&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-bordas-media-stats-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-bordas-media-stats-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.bannersadvert" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-advertising-banners-In-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-advertising-banners-In-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.takeover" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-takeover-In-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-takeover-In-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.exclusivityhomepage" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-exclusivity-homepage-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-exclusivity-homepage-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.socialnetworks" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-social-media-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-reseaux-sociaux-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.newsletter" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-Newsletter-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-Newsletter-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.slidein" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-Slide-In-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-Slide-In-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.producttargetingvideos" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-advertising-video-ciblage-produits-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-advertising-video-ciblage-produits-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                                <li>
                                                    <FormattedMessage id="ressources.seasonalpricing" />&nbsp;
                                                    (<a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-haute-et-basse-periode-en.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="english" /></a>
                                                    / <a href='https://wp.bordasmedia.com/app/uploads/2023/10/media-kit-haute-et-basse-periode-fr.pdf' target='_blank' rel='noopener noreferrer'><FormattedMessage id="french" /></a>)
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h2>Logos</h2>
                                            <ul>
                                                <li>
                                                    <a href='https://wp.bordasmedia.com/app/uploads/2023/10/LOGOS-AUDIOFANZINE.zip' target='_blank' rel='noopener noreferrer'>Audiofanzine.com</a> -
                                                    <a href='https://wp.bordasmedia.com/app/uploads/2023/10/audiofanzine-logos-300dpi.zip' target='_blank' rel='noopener noreferrer'>HD 300 dpi</a> –
                                                    <a href='https://wp.bordasmedia.com/app/uploads/2023/10/audiofanzine-logos-vector.zip' target='_blank' rel='noopener noreferrer'>vector versions</a>
                                                </li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/LOGOS-FUTURE-PRODUCERS.zip' target='_blank' rel='noopener noreferrer'>FutureProducers.com</a></li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/LOGOS-HOME-RECORDING.zip' target='_blank' rel='noopener noreferrer'>HomeRecording.com</a></li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/LOGOS-MUSIC-GEAR-REVIEW.zip' target='_blank' rel='noopener noreferrer'>MusicGearReview.com</a></li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/LOGOS-RIGTALK.zip' target='_blank' rel='noopener noreferrer'>Rig-talk.com</a></li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/logos-looper.zip' target='_blank' rel='noopener noreferrer'>Looper.fr</a></li>
                                                <li>
                                                    <a href='https://wp.bordasmedia.com/app/uploads/2023/10/2016-Awards-logos-WEB.zip' target='_blank' rel='noopener noreferrer'>Awards</a> -
                                                    <a href='https://wp.bordasmedia.com/app/uploads/2023/10/2016-Awards-logos-HD.zip' target='_blank' rel='noopener noreferrer'>HD version</a>
                                                </li>
                                                <li><a href='https://wp.bordasmedia.com/app/uploads/2023/10/BordasMedia-Logos.zip' target='_blank' rel='noopener noreferrer'><FormattedMessage id="ressources.alllogos" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="wp-block-columns column-icon-title small">
                                        <div className="wp-block-column">
                                            <figure className="wp-block-image size-full"><img loading="lazy" width="64" height="64" src="https://wp.bordasmedia.com/app/uploads/2023/10/arrow_left_icon.png" alt="" className="wp-image-176" /></figure>
                                        </div>
                                        <div className="wp-block-column">
                                            <p><Link to="/#presentation"><FormattedMessage id="ressources.backlink" /></Link></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </motion.div>
            }
        </div>
    );
};

export default Ressources;